import React, { useState } from "react"
import "./Careers.css"

import { BsFillPersonFill } from "react-icons/bs"
import { AiFillPhone } from "react-icons/ai"
import { GrMail } from "react-icons/gr"

import careerbackground from "./Assets/career-background.jpg"
import { useNavigate } from "react-router-dom"

import CareersCarousel from "./CareersCarousel"
import SpontaneousApplication from "./SpontaneousApplication"
import AppHeader from "../../ReusableComponents/AppHeader"

function Careers() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setScreenWidth(window.innerWidth)
  }

  const navigation = useNavigate()

  // modal for application
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <div onResize={handleResize} className="careers-main-container">
      <AppHeader
        image={careerbackground}
        title="Careers"
        subTitle="We believe in providing top-notch diagnosis to the ones in need by joining hands together. "
      />
      {/* <AppTitle title="Join us" />
        <AppSubHeading text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua" /> */}
      <div className="careers-about-us">
        <div className="careers-about-content">
          <h4 className="career-side-heading">Our Commitment</h4>
          <p className="career-sub-heading">
            Serving people right from the start
          </p>
          <p className="career-sub-description">
            An initiative taken by the people of this company to serve and
            improvise the post operative brain health disorders, strokes and
            several other conditions that revolve around the effect of neurons.
            We build our innovations based on the idea to improve brain
            plasticity and cognitive health, while considering the complications
            and struggles that patients go through. Growing with moving times is
            the initial key to unlock the dilemma that goes into diagnosing
            brain related health problems, and we trust the ones around us in
            sustaining our vision to make the world a healthier environment.
          </p>
        </div>
        <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=884&q=80"
          className="career-commitment-image"
          alt=""
        />
      </div>

      <div className="careers-employee-voices">
        <div>
          <h4 className="career-employee-heading">
            In the words of our employee
          </h4>
          <p className="career-employee-sub-heading">
            Meet the team that performs their best and gain insights of the
            company from their perspective.  
          </p>
        </div>
        <div className="employee-video-carousel">
          <CareersCarousel
            employee={true}
            slides={screenWidth > 1100 ? 3 : screenWidth < 500 ? 1 : 2}
          />
        </div>
      </div>
      <SpontaneousApplication show={show} handleClose={handleClose} />
      <div className="career-page-buttons">
        <button onClick={handleShow} className="career-job-buttons">
          Send a spontaneous application
        </button>
        <button
          onClick={() => navigation("/jobsearch")}
          className="career-job-buttons"
        >
          Job Search Page
        </button>
      </div>

      <div className="careers-about-us">
        <img
          src="https://images.unsplash.com/photo-1625750331870-624de6fd3452?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          className="career-commitment-image responsive-large"
          alt=""
        />
        <div className="careers-about-content">
          <h4 className="career-side-heading">Career Advancement</h4>
          <p className="career-sub-heading">
            Work with the best and thrive in this diverse work culture
          </p>
          <p className="career-sub-description">
            Our goal is to provide the best of facilities in the field of
            neurosensing and we seek for a work culture that gives talented
            workers who are willing to contribute in the company. Being a
            start-up, it gives the right kind of exposure, while making the
            working environment effortless and enlightening. What comes more are
            the attractive packages that tag along with the job offers. One gets
            to work in a dynamic workplace followed with innovative projects and
            several opportunities in the field of medicine and digital world as
            a combination. We look for goal-oriented individuals committed to
            working and performing their level best and achieving the vision
            that we look forward to.
          </p>
        </div>
        <img
          src="https://images.unsplash.com/photo-1625750331870-624de6fd3452?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          className="career-commitment-image responsive-small"
          alt=""
        />
      </div>

      <div className="careers-about-us">
        <div className="careers-about-content">
          <h4 className="career-side-heading">
            Shape Your Future With Excellence
          </h4>
          <p className="career-sub-heading">
            Our values reflect our potentiality
          </p>
          <p className="career-sub-description">
            Neuro-tech is rising to be one the world's leading sector in the
            near future and shaping your career in this would be just the right
            thing to do. We at Nirvesh acknowledge your passion to work for
            roles that suit you the best be it healthcare, technical,
            operational and managing areas. Professionalism and expertise in
            skill is deeply valued and the motivation to act right and take
            responsibility at different levels is essential. We welcome people
            of highest integrity to work with us in caring those who need us.
            Looking forward to seeing you at this side of the team.
          </p>
        </div>
        <img
          src="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          className="career-commitment-image"
          alt=""
        />
      </div>

      <div className="career-hr-team">
        <div className="hr-getin-touch">
          <h4 className="career-employee-heading hr-touch-heading">
            Get in touch
          </h4>
          <p className="career-sub-heading">Feeling doubtful? Apply anyways.</p>
          <p className="career-employee-sub-heading career-hr-sub-heading">
            A quick and easy guide through the job application portal helps in
            applying easily for a role in the company. Our recruitment team is
            always available to discuss what joining Nirvesh would mean to you.
            For any further queries please reach out via LinkedIn.
          </p>
          <div className="contact-hr-div">
            <a
              href="mailto:hr@nirveshenterprises.com"
              className="hr-contact-number"
            >
              <strong>Mail us at hr@nirveshenterprises.com</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Careers
