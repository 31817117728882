const oppertunitiesData = [
    {
        id : "1",
        position : "Cloud Engineer",
        department : "Research and Development",
        location : "Hyderabad",
        experience : ["2", "3"],
        skills : ["GCP Services", "AWS","Lambda", "EC2 Instance", "RDMS"],
        datePublished : "21-12-2022",
        description : "Your responsibilities will include:Designing, building and managing highly secure, scalable and available architectures, Configuring cloud infrastructure components like networking and security services, Creating the applications and databases that perform on the cloud, Monitoring cloud management and data storage services"
    },
    {
        id : "2",
        position : "Internship",
        department :"All",
        location : ["Hyderabad"],
        experience :["0","1"],
        skills : ["Branding and Marketing", "Embedded Engineer","React-Native", "Java"],
        datePublished : "19-03-2023",
        description : "You available for 6 months, You hold a Bachelor / Master Degree.Interested in the latest trends, digital marketing, and healthy lifestyle, You are analytical, punctual and have excellent (written and spoken) communication skills"
    },
    // {
    //     id : "3",
    //     position : "Backend Engineer",
    //     department : "IT",
    //     location : "Hyderabad",
    //     experience : ["8", "10"],
    //     skills : ["lorem", "ipsum","dolor", "sit", "amet"],
    //     datePublished : "21-12-2022",
    //     description : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur aliquid inventore ratione. Eaque, voluptatem a expedita illum qui voluptas est quasi inventore, aspernatur cumque, sit ipsa eum rerum voluptatum! Tempore maxime enim excepturi distinctio possimus eveniet quod saepe quos a?"
    // },
    // {
    //     id : "4",
    //     position : "Cloud Engineer",
    //     department : "IT",
    //     location : "Hyderabad",
    //     experience : ["8", "10"],
    //     skills : ["lorem", "ipsum","dolor", "sit", "amet"],
    //     datePublished : "21-12-2022",
    //     description : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur aliquid inventore ratione. Eaque, voluptatem a expedita illum qui voluptas est quasi inventore, aspernatur cumque, sit ipsa eum rerum voluptatum! Tempore maxime enim excepturi distinctio possimus eveniet quod saepe quos a?"
    // },
    // {
    //     id : "5",
    //     position : "Product Manager",
    //     department : "Production",
    //     location : "Hyderabad",
    //     experience : ["10", "15"],
    //     skills : ["lorem", "ipsum","dolor", "sit", "amet"],
    //     datePublished : "21-12-2022",
    //     description : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur aliquid inventore ratione. Eaque, voluptatem a expedita illum qui voluptas est quasi inventore, aspernatur cumque, sit ipsa eum rerum voluptatum! Tempore maxime enim excepturi distinctio possimus eveniet quod saepe quos a?"
    // },
    // {
    //     id : "6",
    //     position : "Product Manager",
    //     department : "Production",
    //     location : "Hyderabad",
    //     experience : ["10", "15"],
    //     skills : ["lorem", "ipsum","dolor", "sit", "amet"],
    //     datePublished : "21-12-2022",
    //     description : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur aliquid inventore ratione. Eaque, voluptatem a expedita illum qui voluptas est quasi inventore, aspernatur cumque, sit ipsa eum rerum voluptatum! Tempore maxime enim excepturi distinctio possimus eveniet quod saepe quos a?"
    // },
    // {
    //     id : "7",
    //     position : "Product Manager",
    //     department : "Production",
    //     location : "Hyderabad",
    //     experience : ["10", "15"],
    //     skills : ["lorem", "ipsum","dolor", "sit", "amet"],
    //     datePublished : "21-12-2022",
    //     description : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur aliquid inventore ratione. Eaque, voluptatem a expedita illum qui voluptas est quasi inventore, aspernatur cumque, sit ipsa eum rerum voluptatum! Tempore maxime enim excepturi distinctio possimus eveniet quod saepe quos a?"
    // },
    // {
    //     id : "8",
    //     position : "Product Manager",
    //     department : "Production",
    //     location : "Hyderabad",
    //     experience : ["10", "15"],
    //     skills : ["lorem", "ipsum","dolor", "sit", "amet"],
    //     datePublished : "21-12-2022",
    //     description : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur aliquid inventore ratione. Eaque, voluptatem a expedita illum qui voluptas est quasi inventore, aspernatur cumque, sit ipsa eum rerum voluptatum! Tempore maxime enim excepturi distinctio possimus eveniet quod saepe quos a?"
    // },
    // {
    //     id : "9",
    //     position : "Product Manager",
    //     department : "Production",
    //     location : "Hyderabad",
    //     experience : ["10", "15"],
    //     skills : ["lorem", "ipsum","dolor", "sit", "amet"],
    //     datePublished : "21-12-2022",
    //     description : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur aliquid inventore ratione. Eaque, voluptatem a expedita illum qui voluptas est quasi inventore, aspernatur cumque, sit ipsa eum rerum voluptatum! Tempore maxime enim excepturi distinctio possimus eveniet quod saepe quos a?"
    // },
    // {
    //     id : "10",
    //     position : "Product Manager",
    //     department : "Production",
    //     location : "Hyderabad",
    //     experience : ["10", "15"],
    //     skills : ["lorem", "ipsum","dolor", "sit", "amet"],
    //     datePublished : "21-12-2022",
    //     description : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur aliquid inventore ratione. Eaque, voluptatem a expedita illum qui voluptas est quasi inventore, aspernatur cumque, sit ipsa eum rerum voluptatum! Tempore maxime enim excepturi distinctio possimus eveniet quod saepe quos a?"
    // },
    // {
    //     id : "11",
    //     position : "Product Manager",
    //     department : "Production",
    //     location : "Hyderabad",
    //     experience : ["10", "15"],
    //     skills : ["lorem", "ipsum","dolor", "sit", "amet"],
    //     datePublished : "21-12-2022",
    //     description : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur aliquid inventore ratione. Eaque, voluptatem a expedita illum qui voluptas est quasi inventore, aspernatur cumque, sit ipsa eum rerum voluptatum! Tempore maxime enim excepturi distinctio possimus eveniet quod saepe quos a?"
    // },
    // {
    //     id : "12",
    //     position : "Product Manager",
    //     department : "Production",
    //     location : "Hyderabad",
    //     experience : ["10", "15"],
    //     skills : ["lorem", "ipsum","dolor", "sit", "amet"],
    //     datePublished : "21-12-2022",
    //     description : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur aliquid inventore ratione. Eaque, voluptatem a expedita illum qui voluptas est quasi inventore, aspernatur cumque, sit ipsa eum rerum voluptatum! Tempore maxime enim excepturi distinctio possimus eveniet quod saepe quos a?"
    // },
];

export {oppertunitiesData};