import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import Alert from "react-bootstrap/Alert"
import axios from "axios"

import { MdClose } from "react-icons/md"
import { BsFillPersonFill } from "react-icons/bs"
import { AiFillPhone } from "react-icons/ai"
import { GrMail } from "react-icons/gr"
function SpontaneousApplication({ show, handleClose }) {
  const closeModal = () => handleClose()

  const [submit, setSubmit] = useState(false)
  const [submitText, setSubmitText] = useState({
    color: "",
    text: "",
  })

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    message: "",
    cv: "",
    cvname: "",
  })

  const OnChange = (event) => {
    if (event.target.name == "cv" && event.target.files[0]) {
      setFormData((oldState) => {
        return {
          ...oldState,
          cv: event.target.files[0],
          cvname: event.target.files[0].name,
        }
      })
    } else {
      setFormData((oldState) => {
        return {
          ...oldState,
          [event.target.name]: event.target.value,
        }
      })
    }
  }

  const config = {
    headers: { "content-type": "multipart/form-data" },
  }

  const handleSubmit = () => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.message ||
      !formData.cv
    ) {
      setSubmitText({
        color: "red",
        text: "Please fill the form completely before submitting!",
      })
      setSubmit(true)
      setTimeout(() => {
        setSubmit(false)
      }, 2000)
      return
    }

    setSubmitText({
      text: "Application is processing...",
    })
    setSubmit(true)
    let fileData = new FormData()

    fileData.append("name", formData.name)
    fileData.append("email", formData.email)
    fileData.append("phoneNumber", formData.phoneNumber)
    fileData.append("message", formData.message)
    fileData.append("cv", formData.cv, formData.cvname)
    // fileData.append("ml", formData.ml)
    axios
      .post("http://13.126.94.16:8080/sendmail", fileData, config)
      .then((response) => {
        if (response.data.status == 200) {
          setSubmitText({
            text: "Application sent sucessfully!",
          })
          setFormData({
            name: "",
            phoneNumber: "",
            email: "",
            message: "",
            cv: "",
          })
          setTimeout(() => {
            setSubmit(false)
          }, 2000)
        } else if (response.data.status == 403) {
          console.log(response)
          setSubmitText({
            color: "red",
            text: response.data.message,
          })
          setTimeout(() => {
            setSubmit(false)
          }, 2000)
        }
      })
      .catch((error) => {
        setSubmit(true)
        setSubmitText({
          color: "red",
          text: error.message,
        })
        setTimeout(() => {
          setSubmit(false)
        }, 2000)
      })
  }
  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <div className="careers-application-container">
          <div className="careers-application-header">
            <MdClose onClick={closeModal} className="modal-close-button" />
            <p>Application Form</p>
          </div>
          <div className="careers-application-content">
            <label htmlFor="name" className="careers-application-label">
              Name of the applicant
            </label>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              className="careers-application-input"
              onChange={(e) => OnChange(e)}
              value={formData.name}
            />
            <BsFillPersonFill className="career-input-icon" />
            <label htmlFor="phoneNumber" className="careers-application-label">
              Phone Number
            </label>
            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              className="careers-application-input"
              onChange={OnChange}
              value={formData.phoneNumber}
            />
            <AiFillPhone className="career-input-icon" />

            <label htmlFor="email" className="careers-application-label">
              Email ID
            </label>
            <input
              type="text"
              name="email"
              placeholder="Email ID"
              className="careers-application-input"
              onChange={OnChange}
              value={formData.email}
            />
            <GrMail className="career-input-icon" />

            <label htmlFor="message" className="careers-application-label">
              Message
            </label>
            <textarea
              name="message"
              className="careers-application-input"
              onChange={OnChange}
              value={formData.message}
            />
          </div>
          <div className="job-desciprtion-file-container">
            <label htmlFor="cv">
              Upload your resume along with motivation letter
            </label>
            <input
              type="file"
              id="cv"
              name="cv"
              onChange={OnChange}
              accept=".pdf"
            />
          </div>
          <div className="job-button-container">
            <button
              disabled={submit}
              onClick={handleSubmit}
              className="job-application-button"
            >
              Apply
            </button>
          </div>
          {submit && (
            <Alert
              variant="success"
              style={{
                textAlign: "center",
                backgroundColor: submitText.color,
                fontSize: "1.2rem",
              }}
            >
              {submitText.text}
            </Alert>
          )}
        </div>
      </Modal>
    </>
  )
}

export default SpontaneousApplication
