import React from "react"

import "./Testimonial.css"
// import AppTitle from "../../ReusableComponents/AppTitle"
// import TestimonialCard from "./TestimonialCard"
import comments from "./TestimonialData"
import science from "./Assets/science2.jpg"

// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react"
// import { Autoplay } from "swiper"
// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

// import { Pagination, Navigation } from "swiper"

import AppHeader from "../../ReusableComponents/AppHeader"

import eeg from "./Assets/eeg2.jpg"
import graphs from "./Assets/graphs.jpg"
import microscope from "./Assets/microscope.jpg"
import machine from "./Assets/machine.jpg"
import machine2 from "./Assets/machine2.jpg"
import brain from "./Assets/braincare.jpg"

function TheScience() {
  return (
    <div className="test-container">
      <div>
        <AppHeader
          image={science}
          title="The Science"
          subTitle="Scientific Scenes at a glance!"
        />
      </div>
      <div className="science-innerContainer">
        <div className="science-contentContainer">
          <p className="science-subHeading">
            The analysis of brain using electroencephalogram or EEG:
          </p>
          <p className="science-description">
            This technology uses the complex information retrieved from the
            areas of cortex to lay out facts about the brain cells. The
            Occipital cortex allows EEG to experiment with visual stimuli to
            figure out effects taking place in the rear most part of the brain.
            Parietal cortex determines the body coordination and movements and
            is crucial in merging data from the external sources and internal
            sensory to produce a coherent representation of how our body
            responds to the external environment. While Temporal cortex and
            Frontal cortex each have their own duty of processing and execution,
            EEGs often rely on their stimulation to give out particulars and
            happenings in the brain.{" "}
          </p>
        </div>
        <img src={eeg} alt="sciencepic" className="science-image" />
      </div>
      <div className="science-innerContainer">
        <img
          src={graphs}
          alt="sciencepic"
          className="science-image show-science-images"
        />

        <div className="science-contentContainer">
          <p className="science-subHeading">Fascinating facts about fNIRS:</p>
          <p className="science-description">
            fNIRS tests take absolute critical care by using optical brain
            technique using near infrared spectroscopy for neuroimaging to
            evaluate the cerebral oxygenation levels. Through the detection of
            different molecules of oxyhaemoglobin and deoxyhaemoglobin, and
            measuring the change in relative concentrations of these absorptions
            the analysis of cerebral functions is estimated. Thus, Neurovascular
            coupling is a primary of fNIRS.
          </p>
        </div>
        <img
          src={graphs}
          alt="sciencepic"
          className="science-image hide-science-images"
        />
      </div>
      <div className="science-innerContainer">
        <div className="science-contentContainer">
          <p className="science-subHeading">
            Inter-relation between brain injury and mental health:
          </p>
          <p className="science-description">
            The mental health of a person hugely determines how one does
            socially and having a disguised condition seems to offtrack the
            person from daily life. The enigma behind understanding the severity
            of aftereffects of brain injury is less and thus all the research
            revolving around neuroscience and its disorders is to retire the
            concept of incurable illness that brain injuries are treated as.
          </p>
        </div>
        <img src={microscope} alt="sciencepic" className="science-image" />
      </div>
      <div className="science-innerContainer">
        <img
          src={brain}
          alt="sciencepic"
          className="science-image show-science-images"
        />
        <div className="science-contentContainer">
          <p className="science-subHeading">Neuro nurturing:</p>
          <p className="science-description">
            Sounds intriguing? Well, the idea behind advancement of neuroscience
            is to develop and nurture the brain cells post accidents. Brain
            injuries refine cognitive health and can lead to problems including
            depression, anxiety, anger, mood swings, regulation in emotions
            among others. Hence comes the role of medical professionals,
            Neuropsychologists and Neuropsychiatrists diagnose for patients
            suffering from brain injuries and the impact it has on the overall
            human development by studying and monitoring different behavioural
            patterns.
          </p>
        </div>
        <img
          src={brain}
          alt="sciencepic"
          className="science-image hide-science-images"
        />
      </div>
      <div className="science-innerContainer">
        <div className="science-contentContainer">
          <p className="science-subHeading">The present tech:</p>
          <p className="science-description">
            The current medical field has improved significantly as compared to
            the last decade and would henceforth continue to do so. Brain
            injuries lead to chemical imbalances due to which chronic paralysis
            takes place among several others like Epilepsy, Dementia,
            Parkinson’s. The present technology uses Brain Computer Interface
            that analyses brain signals and uses the idea of human computer
            interaction to provide direct communication between the brain and
            external machines. This not only displays the advancement of
            technology in terms of human nervous system but also paves way for a
            better replacement.
          </p>
        </div>
        <img src={machine} alt="sciencepic" className="science-image" />
      </div>
      <div className="science-innerContainer">
        <img
          src={machine2}
          alt="sciencepic"
          className="science-image show-science-images"
        />
        <div className="science-contentContainer">
          <p className="science-subHeading">
            The advancement of Neurotechnology:
          </p>
          <p className="science-description">
            In the forthcoming years Neurotechnology will likely to dominate the
            healthcare sector with a rise in around USD 40 billion by the next
            decade. Neurotech has several subdivisions majorly constituting of
            neuroprosthetics, neuromodulation, neurohabitulation and
            neurosensing with the largest market in neurosensing. Companies and
            investors have already refined their businesses to expand and
            improvise in this sector as it has the potential to impact millions
            of lives with just a click away in the near future.
          </p>
        </div>
        <img
          src={machine2}
          alt="sciencepic"
          className="science-image hide-science-images"
        />
      </div>
    </div>
  )
}

export default TheScience
