import employeeImage from "./Assets/team-pic.jpg"
import hrEmployee from "./Assets/employee-image.jpg"

import Pranjali from "./Assets/TeamPics/pranjali2.jpg"
import Eesha from "./Assets/TeamPics/Eesha.jpeg"
import soyal from "./Assets/TeamPics/Soyal.jpg"
import guna from "./Assets/TeamPics/Guna.jpg"
import satya from "./Assets/TeamPics/satya.jpg"
const employeeVideoData = [
  {
    id: "1",
    title: "Meet our employee",
    subTitle: "Dr. Pranjali Gajbhiye, PhD",
    img: Pranjali,
    comment: "About Nirvesh",
    flipped: false,
    words:
      "Nirvesh enterprises bring together talented people with a shared vision and passion who can find innovative solutions to real problems. The company's multidisciplinary nature defines our corporate culture, the team's experience and knowledge, the freedom to own a problem and work towards a solution without being hindered by management and founders makes every day here interesting and fun. ",
    comment: "Freedom to own a problem and working towards it together",
  },
  {
    id: "3",
    title: "Meet our employee",
    subTitle: "Soyal Kumar",
    img: soyal,
    flipped: false,
    words:
      "Nirvesh Enterprises is a budding company that provides a platform to learn and execute ideas with focus on safety, reliability and work-life balance. The employees support each other and the organization has a positive environment, striving to bring a revolutionary product to the neurological sector.",
    comment:
      "4 words -  Positive Environment, Work-Life Balance, Reliability, Innovation",
  },
  {
    id: "4",
    title: "Meet our employee",
    subTitle: "Guna Deepak",
    img: guna,
    flipped: false,
    words:
      "Hi! My name is Guna; I am a Product Designer. I work on the development of the physical product of the Synapsil in Nirvesh Enterprises private Limited. I like the challenge of creating/developing the models (wearables) which help the society lot with the outcomes from the product. It helps so many people who need help from post trauma. Working Environment is very enjoyable in company.",
    comment: "Enjoyed working everyday here at Nirvesh",
  },
  {
    id: "5",
    title: "Meet our employee",
    subTitle: "Esha Chowdary",
    img: Eesha,
    flipped: false,
    words:
      "Nirvesh Enterprises provides great  support and opportunities to learn and for professional development. I like the mindset and culture. I love being part of a team and a company that gives its best and is doing meaningful innovative work.",
    comment:
      "Glad to be a part of Nirvesh Team and really like the mindset and culture here",
  },
  {
    id: "6",
    title: "Meet our employee",
    subTitle: "Satya Ganesh",
    img: satya,
    comment: "Nirvesh has a mind set of growing together",
    flipped: false,
    words:
      "Switching from mechanical to software has been a difficult journey for me. And at times I have no hope. But After joining nirvesh and met these amazing people with the mindset of growing together and helping eachother, I was flattered. Along with that Every employee is always given a chance to share his/her problems or ideas which helps to establish a healthy relationship. I’m really glad to be a part of nirvesh’s journey.",
    comment:
      "Nirvesh has a minset of growing together and freedom to express individual ideas",
  },
]
const hrEmployeeDetails = [
  {
    id: "1",
    name: "Anand Gupta",
    base: "Talent Aquisition",
    position: "Manager",
    img: hrEmployee,
    Linkedin: "",
  },
  {
    id: "2",
    name: "Anand Gupta",
    base: "Talent Aquisition",
    position: "Manager",
    img: hrEmployee,
    Linkedin: "",
  },
  {
    id: "3",
    name: "Anand Gupta",
    base: "Talent Aquisition",
    position: "Manager",
    img: hrEmployee,
    Linkedin: "",
  },
  {
    id: "4",
    name: "Anand Gupta",
    base: "Talent Aquisition",
    position: "Manager",
    img: hrEmployee,
    Linkedin: "",
  },
]

export { employeeVideoData, hrEmployeeDetails }
