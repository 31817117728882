import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import CareerHrTeamCard from "./CareerHrTeamCard";
import { hrEmployeeDetails, employeeVideoData } from "./CareerData";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./Careers.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import CareerEmployeeCard from "./CareerEmployeeCard";

export default function CareersCarousel({hr, employee, slides}) {

  const [employeeTestimonial, setEmployeeTestimonial] =  useState(employeeVideoData);

  const handleFlipping = (id) => {
    let employeeFlippedCard =  employeeTestimonial.map(employee => {
      if(employee.id === id){
        return {
          ...employee,
          flipped : !employee.flipped,
        }
      }else{
        return {
          ...employee,
          flipped : false,
        }
      }
    });

    setEmployeeTestimonial(employeeFlippedCard);
  }
  return (

      <Swiper
        slidesPerView={slides}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        
        autoplay={{
            delay: 4500,
            disableOnInteraction: true,
            pauseOnMouseEnter : true
          }}
          modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        { hr && hrEmployeeDetails.map(item => 
            <SwiperSlide>{<CareerHrTeamCard item={item} />}</SwiperSlide>
        )}
        { employee && employeeTestimonial.map(item => 
            <SwiperSlide>{<CareerEmployeeCard item={item} handleFlipCard = {(id) => handleFlipping(id)} />}</SwiperSlide>
        )}
        
      </Swiper>
  );
}
