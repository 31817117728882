import React, { useState } from "react"
import { BsArrowRight } from "react-icons/bs"
import { BsArrowLeft } from "react-icons/bs"
import { FaQuoteLeft } from "react-icons/fa"
import { FaQuoteRight } from "react-icons/fa"

import ReactCardFlip from "react-card-flip"

function CareerEmployeeCard({ item, handleFlipCard }) {
  const sendId = () => {
    handleFlipCard(item.id)
  }

  return (
    <ReactCardFlip
      isFlipped={item.flipped}
      flipDirection="vertical"
      key={item.id}
    >
      <div className="career-employee-card" key={item.id}>
        <img
          src={item.img}
          alt="profile-click"
          className="career-employee-video-image"
        />
        <p className="career-employee-video-title">{item.title}</p>
        <p className="career-employee-video-sub-title">{item.subTitle}</p>
        <p className="career-employee-comment">{item.comment}</p>
        <p className="career-employee-know" onClick={sendId}>
          know more <BsArrowRight className="career-employee-arrow" />
        </p>
      </div>
      <div className="career-employee-card-backside">
        <FaQuoteLeft className="employee-quote-left" />
        <p className="career-employee-words">{item.words}</p>
        <FaQuoteRight className="employee-quote-right" />

        <p className="career-employee-know" onClick={sendId}>
          Go back <BsArrowLeft className="career-employee-arrow" />
        </p>
      </div>
    </ReactCardFlip>
  )
}

export default CareerEmployeeCard
